import React from 'react'

const Ourservices = () => {
  return (
    <>
     <div className="bg-[color:var(--primary-color)]">
        <div id="service" className="max-w-[1200px] m-auto py-[30px] px-4">
          <img
            className=" w-[150px] m-auto"
            src="./assests/oursolutions.png"
            alt=""
          />
          <h2 className="text-4xl font-inter text-center font-bold text-slate-900">
            Our Services
          </h2>
          <div>
            <div className="grid gap-10 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 mt-10 m-auto">
              {/* Card 01 */}
              <a href="/">
              <div className="bg-[color:var(--dark-color)] rounded-[63px]  border border-[color:var(--dark-color)] w-[250px] h-[250px] m-auto">
                <div>
                  <img
                    className="h-full w-[95px] object-cover p-2 rounded-full m-auto"
                    src="./assests/weblogo.png"
                    alt="Img Here"
                  />
                </div>
                <div className="p-3">
                  <h1 className="text-[color:var(--primary-color)] text-[21px] mb-2 mt-[-22px] ml-7 font-semibold">
                    WEB SOLUTIONS
                  </h1>
                  <p className="text-[13.5px] text-justify mb-3 text-[color:var(--light-color)] ml-1">
                    Innovative and Transformative Web Solutions, Expertly
                    Tailored for Your Unparalleled Success in the Digital
                    Landscape.
                  </p>
                </div>
              </div>
              </a>
              {/* Card 02 */}
              <a href="/">
              <div className="bg-[color:var(--dark-color)] rounded-[63px]  border border-[color:var(--dark-color)] w-[250px] h-[250px] m-auto">
                <div>
                  <img
                    className="h-full w-[75px] object-cover p-2 m-auto mt-[10px]"
                    src="./assests/applogo.png"
                    alt="Img Here"
                  />
                </div>
                <div className="p-3">
                  <h1 className="text-[color:var(--primary-color)] text-[21px] mb-2 mt-[-10px] ml-10 font-semibold">
                    ANDROID/IOS
                  </h1>
                  <p className="text-[13.5px] text-justify mb-3 text-[color:var(--light-color)] ml-1">
                    Crafting Seamless Mobile Experiences Elevate Your Presence
                    with Expert Android and IOS App Development.
                  </p>
                </div>
              </div>
              </a>
              {/* Card 03 */}
              <a href="/">
              <div className="bg-[color:var(--dark-color)] rounded-[63px]  border border-[color:var(--dark-color)] w-[250px] h-[250px] m-auto">
                <div>
                  <img
                    className="h-full w-[95px] object-cover p-2 m-auto"
                    src="./assests/softwarelogo.png"
                    alt="Img Here"
                  />
                </div>
                <div className="p-3">
                  <h1 className="text-[color:var(--primary-color)] text-[21px] w-[250px] mb-2 mt-[-22px] ml-[-5px] font-semibold ml-[-11px]">
                    SOFTWARE SOLUTIONS
                  </h1>
                  <p className="text-[13.5px] text-justify mb-3 text-[color:var(--light-color)] ml-1">
                    Precision Software Development for Scalable Solutions,
                    Efficiency, and Unmatched Success in the Digital
                    Transformation Journey
                  </p>
                  
                </div>
              </div>
              </a>
              {/* Card 04 */}
              <a href="/">
              <div className="bg-[color:var(--dark-color)] rounded-[63px]  border border-[color:var(--dark-color)] w-[250px] h-[250px] m-auto">
                <div>
                  <img
                    className="h-full w-[95px] object-cover p-2 rounded-full m-auto"
                    src="./assests/cvlogo.png"
                    alt="Img Here"
                  />
                </div>
                <div className="p-3">
                  <h1 className="text-[color:var(--primary-color)] text-[21px] mb-2 mt-[-22px] ml-4 font-semibold">
                    COMPUTER VISION
                  </h1>
                  <p className="text-[13.5px] text-justify mb-3 text-[color:var(--light-color)] ml-1">
                    Transforming Vision into Insight: Precision, Power,
                    Possibilities with Our Cutting-Edge Computer Vision
                    Services.
                  </p>
                </div>
              </div>
              </a>
              {/* Card 05 */}
              <a href="/">
              <div className="bg-[color:var(--dark-color)] rounded-[63px]  border border-[color:var(--dark-color)] w-[250px] h-[250px] m-auto">
                <div>
                  <img
                    className="h-full w-[90px] object-cover p-2 m-auto mt-1"
                    src="./assests/graphiclogo.png"
                    alt="Img Here"
                  />
                </div>
                <div className="p-3">
                  <h1 className="text-[color:var(--primary-color)] text-[21px] mb-2 mt-[-18px] font-semibold">
                    GRAPHIC DESIGNING
                  </h1>
                  <p className="text-[13.5px] text-justify mb-3 text-[color:var(--light-color)] ml-1">
                    Crafting Visual Excellence Elevate Your Brand with
                    Innovative Designs through Our
                    Graphic Designing Expertise.
                  </p>
                </div>
              </div>
              </a>
              {/* Card 06 */}
              <a href="/">
              <div className="bg-[color:var(--dark-color)] rounded-[63px]  border border-[color:var(--dark-color)] w-[250px] h-[250px] m-auto">
                <div>
                  <img
                    className="h-full w-[93px] object-cover p-2 m-auto"
                    src="./assests/editinglogo.png"
                    alt="Img Here"
                  />
                </div>
                <div className="p-3">
                  <h1 className="text-[color:var(--primary-color)] text-[21px] mb-2 mt-[-18px] ml-9 font-semibold">
                    VIDEO EDITING
                  </h1>
                  <p className="text-[13.5px] text-justify mb-3 text-[color:var(--light-color)] ml-1">
                    Elevate Your Story: Seamless Editing, Dynamic Effects, and
                    Flawless Transitions with Our Expert Video Editing Services.
                  </p>
                </div>
              </div>
              </a>
              {/* Card 07 */}
              <a href="/">
              <div className="bg-[color:var(--dark-color)] rounded-[63px]  border border-[color:var(--dark-color)] w-[250px] h-[250px] m-auto">
                <div>
                  <img
                    className="h-full w-[105px] object-cover p-2 rounded-full mt-[-7px] m-auto"
                    src="./assests/marketinglogo.png"
                    alt="Img Here"
                  />
                </div>
                <div className="p-3">
                  <h1 className="text-[color:var(--primary-color)] text-[22px] mb-2 mt-[-22px] ml-4 font-semibold">
                    Digital Marketing
                  </h1>
                  <p className="text-[13.5px] text-justify mb-3 text-[color:var(--light-color)] ml-1">
                    Digital Domination Begins Here: Boost Your Brand, Maximize
                    Reach, and Drive Results with Our Strategic Digital
                    Marketing Solutions.
                  </p>
                </div>
              </div>
              </a>
              {/* Card 08 */}
              <a href="/">
              <div className="bg-[color:var(--dark-color)] rounded-[63px]  border border-[color:var(--dark-color)] w-[250px] h-[250px] m-auto">
                <div>
                  <img
                    className="h-full w-[105px] object-cover p-2 m-auto mt-[-5px]"
                    src="./assests/seologo.png"
                    alt="Img Here"
                  />
                </div>
                <div className="p-3">
                  <h1 className="text-[color:var(--primary-color)] text-[21px] mb-2 mt-[-22px] ml-8 font-semibold">
                    SEO SOLUTIONS
                  </h1>
                  <p className="text-[13.5px] text-justify mb-3 text-[color:var(--light-color)] ml-1">
                    Elevate Your Visibility: Precision-Engineered SEO Solutions
                    for Top Rankings, Increased Traffic, and Sustainable Online
                    Success.
                  </p>
                </div>
              </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Ourservices