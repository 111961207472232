import React from "react";
import { Route, Routes } from "react-router-dom"
import Home from "./pages/home/Home";
import About from "./pages/about/About";
import Ourservices from "./pages/services/Ourservices";
import "./App.css"
function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/ourservices" element={<Ourservices />} />
      </Routes>
    </>
  );
}

export default App;
