// Navbar.jsx

import React, { useState } from 'react';
import './Navbar.css';
const Navbar = () => {
  const [showMenu, setShowMenu] = useState(false);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  return (
    <nav>
      <a href="/">
      <img src="./assests/logo.png" alt="" />
      </a>
      <div className={`menu-toggle ${showMenu ? 'open' : ''}`} onClick={toggleMenu}>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <ul className={showMenu ? 'show' : ''}>
        <li className='transform transition duration-500 hover:scale-125'><a href="/">Home</a></li>
        <li className='transform transition duration-500 hover:scale-125'><a href="/about">About</a></li>
        <li className='transform transition duration-500 hover:scale-125'><a href="/ourservices">Services</a></li>
        <li className='transform transition duration-500 hover:scale-125'><a href="/portfolio">Portfolio</a></li>
        <li className='transform transition duration-500 hover:scale-125'><a href="/contact">Contact  </a></li>
      </ul>
    </nav>
  );
};

export default Navbar;
