import React from "react";

const About = () => {
  return (
    <>
      <title>About - The Future Is Now</title>
      <div className="bg-[#000b24] m-auto py-1">
        <div className="max-w-[1250px] m-auto">
          <div className="grid grid-cols-1 gap-2 md:grid-cols-1 lg:grid-cols-1 mx-12 mt-10">
            <div className="text-[color:var(--primary-color)] rounded-3xl">
              <img
                className="h-32 w-32 object-cover m-auto mb-5"
                src="/assests/about.png"
                alt="img alter"
              />
              <div>
                <h1 className="mb-2 font-bold text-5xl text-center mt-[-10px]">
                  About Us
                </h1>
                <p className="text-center mt-[40px] lg:text-[22px] lg:w-[1160px]">
                  Welcome to the world of Innoventis, where we transcend the
                  conventional and emerge as architects of progress in the
                  ever-evolving landscape of technology. Our odyssey embarked
                  with a steadfast commitment to reshape the limits of what is
                  attainable in the vast expanse of the digital realm.
                </p>
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <div className="text-[color:var(--primary-color)] rounded-3xl mt-[100px]">
                <img
                  className="w-40 object-cover m-auto mb-5"
                  src="/assests/mission.png"
                  alt="img alter"
                />
                <div>
                  <h1 className="mb-2 font-bold text-5xl text-center">
                    Mission
                  </h1>
                  <p className="text-center mt-[40px] lg:text-[22px]">
                    Driven by a passion for innovation, our mission is to
                    empower businesses through tailored IT solutions that
                    optimize performance, enhance efficiency, and foster growth.
                    We believe in the transformative power of technology and its
                    ability to make a meaningful impact on businesses and
                    society.
                  </p>
                </div>
              </div>
              <div className="text-[color:var(--primary-color)] rounded-3xl mt-[100px]">
                <img
                  className="w-40 object-cover m-auto mb-5"
                  src="/assests/vision.png"
                  alt="img alter"
                />
                <div>
                  <h1 className="mb-2 font-bold text-5xl text-center">
                    Vision
                  </h1>
                  <p className="text-center mt-[40px] lg:text-[22px]">
                    In our vision, Innoventis is more than an IT company; it's a
                    partner in progress, a beacon of inspiration for businesses
                    seeking not just success, but significance. As we chart the
                    course ahead, our vision guides us to continually push
                    boundaries, embrace challenges, and shape a future where the
                    intersection of technology and humanity is harmonious and
                    impactful.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Cards  */}
        <div className="max-w-[1250px] m-auto py-20 px-4 mt-28">
          <h2 className="text-5xl mt-2 font-inter text-center font-bold text-[color:var(--primary-color)]">
            Core Values
          </h2>
          <div>
            <div className="grid gap-2 grid-cols-1 sm:grid-cols-3 lg:grid-cols-4 mt-10">
              <div className="p-8 group flex flex-col items-center gap-2 m-auto transform transition duration-500 hover:scale-125">
                <img
                  className="h-[230px] w-[300px] object-contain bg-[#0C1765] rounded-[30px]"
                  src="/assests/innovation.png"
                  alt="Img Here"
                />
                <span className="text-xl font-semibold text-[color:var(--primary-color)]">
                  Innovation
                </span>
              </div>
              <div className="p-8 group flex flex-col items-center gap-2 transform transition duration-500 hover:scale-125">
                <img
                  className="h-[230px] w-[300px] object-contain bg-[#0C1765] rounded-[30px]"
                  src="/assests/integrity.png"
                  alt="Img here"
                />
                <span className="text-xl font-semibold text-[color:var(--primary-color)]">
                  Integrity
                </span>
              </div>
              <div className="p-8 group flex flex-col items-center gap-2 transform transition duration-500 hover:scale-125">
                <img
                  className="h-[230px] w-[300px] object-contain bg-[#0C1765] rounded-[30px]"
                  src="/assests/excellence.png"
                  alt="Img here"
                />
                <span className="text-xl font-semibold text-[color:var(--primary-color)]">
                  Excellence
                </span>
              </div>
              <div className="p-8 group flex flex-col items-center gap-2 transform transition duration-500 hover:scale-125">
                <img
                  className="h-[230px] w-[300px] object-contain bg-[#0C1765] rounded-[30px]"
                  src="/assests/collaboration.png"
                  alt="Img Here"
                />
                <span className="text-xl font-semibold text-[color:var(--primary-color)]">
                  Collaboration
                </span>
              </div>
            </div>
          </div>
          <div>
        <div className="max-w-[1200px] m-auto py-[30px] px-4 ">
          <h2 className="text-4xl font-inter text-center font-bold text-[color:var(--dark-color)] mt-[100px] mb-12">
          Our Leaders
          </h2>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-16">
          <div>
          <img
            className="w-[280px] rounded-full m-auto lg:w-[350px] md:w-[280px] sm:[100px]"
            src="./assests/ceo.jpg"
            alt=""
          />
          <h2 className="text-2xl font-inter text-center text-white mt-2 pt-1">
              ZAID ANWAR
              <p className="text-[12px] mt-[-10px]">Founder & CEO </p>
            </h2>
            </div>
            <div>
          <div className="bg-[#000b24] m-auto rounded-full">
            <img className="w-[280px] rounded-full m-auto lg:w-[350px] md:w-[280px] sm:[100px]"
             src="./assests/coo.jpg" alt="" />
          </div>
            <h2 className="text-2xl font-inter text-center text-white mt-2 pt-1">
              USMAN ANWAR
              <p className="text-[12px] mt-[-10px]">Co-Founder & COO </p>
            </h2>
        </div>
        </div>
      </div>
        </div>
      </div>
    </>
  );
};

export default About;
