import React from "react";

const Footer = () => {
  return (
    <>
      <footer className="relative bg-[color:var(--main-color)] pt-8 pb-6">
        <div className="max-w-[1240px] mx-auto px-4 py-8">
          <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2">
            <div className="w-full lg:w-[70%]">
              <a href="/">
                <img
                  className="w-64 mt-[-40px] m-auto"
                  src="/assests/logo.png"
                  alt="Img Here"
                />
              </a>
              <p className="text-sm text-justify mb-3 text-[color:var(--light-color)]">
                At Innoventis, we transform ideas into reality, providing
                businesses with the tools they need to thrive in an
                ever-evolving digital landscape. Elevate your enterprise with
                Innoventis, where innovation converges seamlessly with IT
                brilliance.
              </p>
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-2">
              <div className="">
                <a
                  href="/"
                  className="block uppercase text-lg font-semibold mb-2 text-[color:var(--primary-color)] mt-5"
                >
                  Services
                </a>
                <ul>
                  <li>
                    <a
                      className="text-[color:var(--light-color)] block pb-2 text-sm"
                      href="/"
                    >
                      Web Development
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-[color:var(--light-color)] hover:text-[color:var(--main-color)] block pb-2 text-sm"
                      href="/our-story"
                    >
                      App Development
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-[color:var(--light-color)] block pb-2 text-sm"
                      href="#service"
                    >
                    Software Development
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-[color:var(--light-color)] block pb-2 text-sm"
                      href="/news"
                    >
                      
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-[color:var(--light-color)] block pb-2 text-sm"
                      href="/contact"
                    >
                      Contact Us
                    </a>
                  </li>
                </ul>
              </div>

              <div className="">
                <span className="block uppercase text-lg font-semibold mb-2 text-[color:var(--primary-color)] mt-5">
                  Join our newsletter
                </span>
                <p className="text-sm line-clamp-3 text-[color:var(--light-color)]">
                Subscribe to our newsletter for the latest insights and trends in the IT industry, elevating your technological knowledge and staying ahead in the digital landscape.
                </p>
                <div className="flex items-center mt-2 gap-5">
                  <h2 className="font-semibold text-[color:var(--primary-color)]">Follow Us On</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
