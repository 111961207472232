import React from 'react'
import "./LogoMarquee.css"

const LogoMarquee = () => {
  return (
    <>
    <div class="client-logo-wrap">
  <ul class="client-logo client-logo-left">
    <li><img src="./assests/client1.png" alt="" /></li>
    <li><img src="./assests/client2.png" alt="" /></li>
    <li><img src="./assests/client3.png" alt="" /></li>
    <li><img src="./assests/client4.png" alt="" /></li>
    <li><img src="./assests/client1.png" alt="" /></li>
    <li><img src="./assests/client2.png" alt="" /></li>
    <li><img src="./assests/client3.png" alt="" /></li>
    <li><img src="./assests/client4.png" alt="" /></li>
    <li><img src="./assests/client5.jpg" alt="" /></li>
  </ul>
</div>
    </>
  )
}

export default LogoMarquee