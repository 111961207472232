import React from "react";
import {useEffect } from "react";
import LogoMarquee from "../../components/LogoMarquee";

import AOS from 'aos';
import 'aos/dist/aos.css';
const Home = () => {

  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <>
      <div className="px-2 bg-[#000b24]" id="home">
        <div className="lg:max-w-[1200px] m-auto rounded-lg pr-5 pl-5 pt-5 pb-5">
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-5">
            <div>
              <h2 className="mt-2 text-[55px] font-semibold text-white lg:mt-[80px]">
                FUTURE IS NOW
              </h2>
              <p className="mt-[10px] leading-7 text-slate-300 lg:text-[16px] text-justify">
                Innoventis is a leading software house distinguished by its
                seasoned team of professionals dedicated to innovation. With a
                core focus on delivering cutting-edge solutions, our experienced
                software engineers, designers, and project managers bring a
                wealth of expertise to every project. At Innoventis, innovation
                is ingrained in our culture, and we prioritize a client-centric
                approach, tailoring our services to meet the unique needs of
                each partner.
              </p>
              <a className=" md:block" href={"/contact"}>
                <button className=" mt-6 text-[color:var(--primary-color)] border border-[color:var(--primary-color)] px-4 py-2 rounded-full bg-[color:var(--main-color)] hover:bg-[color:var(--primary-color)] hover:text-[color:var(--main-color)] hover:font-bold transform transition duration-500 hover:scale-125">
                  Get Started
                </button>
              </a>
            </div>
            <div>
              <video
                className="w-[530px] lg:ml-[60px] lg:mt-[-20px]" data-aos="fade-left"
                autoPlay
                muted
                loop
                src="./assests/home.mp4"
              ></video> 
            </div>
          </div>
        </div>
      </div>
      <div className="bg-[color:var(--primary-color)]">
        <div id="service" className="max-w-[1200px] m-auto py-[30px] px-4">
          <img
            className=" w-[150px] m-auto"
            src="./assests/oursolutions.png"
            alt=""
          />
          <h2 className="text-4xl font-inter text-center font-bold text-slate-900">
            Our Services
          </h2>
          <div>
            <div className="grid gap-10 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 mt-10 m-auto">
              {/* Card 01 */}
              <a href="/" className="transform transition duration-500 hover:scale-90">
              <div className="bg-[color:var(--dark-color)] rounded-[63px]  border border-[color:var(--dark-color)] w-[250px] h-[250px] m-auto" data-aos="zoom-out">
                <div>
                  <img
                    className="h-full w-[95px] object-cover p-2 rounded-full m-auto"
                    src="./assests/weblogo.png"
                    alt="Img Here"
                  />
                </div>
                <div className="p-3">
                  <h1 className="text-[color:var(--primary-color)] text-[21px] mb-2 mt-[-22px] ml-7 font-semibold">
                    WEB SOLUTIONS
                  </h1>
                  <p className="text-[13.5px] text-justify mb-3 text-[color:var(--light-color)] ml-1">
                    Innovative and Transformative Web Solutions, Expertly
                    Tailored for Your Unparalleled Success in the Digital
                    Landscape.
                  </p>
                </div>
              </div>
              </a>
              {/* Card 02 */}
              <a href="/" className="transform transition duration-500 hover:scale-90">
              <div className="bg-[color:var(--dark-color)] rounded-[63px]  border border-[color:var(--dark-color)] w-[250px] h-[250px] m-auto transform transition duration-500 hover:scale-90" data-aos="zoom-out">
                <div>
                  <img
                    className="h-full w-[75px] object-cover p-2 m-auto mt-[10px]"
                    src="./assests/applogo.png"
                    alt="Img Here"
                  />
                </div>
                <div className="p-3">
                  <h1 className="text-[color:var(--primary-color)] text-[21px] mb-2 mt-[-10px] ml-10 font-semibold">
                    ANDROID/IOS
                  </h1>
                  <p className="text-[13.5px] text-justify mb-3 text-[color:var(--light-color)] ml-1">
                    Crafting Seamless Mobile Experiences Elevate Your Presence
                    with Expert Android and IOS App Development.
                  </p>
                </div>
              </div>
              </a>
              {/* Card 03 */}
              <a href="/" className="transform transition duration-500 hover:scale-90">
              <div className="bg-[color:var(--dark-color)] rounded-[63px]  border border-[color:var(--dark-color)] w-[250px] h-[250px] m-auto transform transition duration-500 hover:scale-90" data-aos="zoom-out">
                <div>
                  <img
                    className="h-full w-[95px] object-cover p-2 m-auto"
                    src="./assests/softwarelogo.png"
                    alt="Img Here"
                  />
                </div>
                <div className="p-3">
                  <h1 className="text-[color:var(--primary-color)] text-[21px] w-[250px] mb-2 mt-[-22px] ml-[-5px] font-semibold ml-[-11px]">
                    SOFTWARE SOLUTIONS
                  </h1>
                  <p className="text-[13.5px] text-justify mb-3 text-[color:var(--light-color)] ml-1">
                    Precision Software Development for Scalable Solutions,
                    Efficiency, and Unmatched Success in the Digital
                    Transformation Journey
                  </p>
                  
                </div>
              </div>
              </a>
              {/* Card 04 */}
              <a href="/" className="transform transition duration-500 hover:scale-90">
              <div className="bg-[color:var(--dark-color)] rounded-[63px]  border border-[color:var(--dark-color)] w-[250px] h-[250px] m-auto transform transition duration-500 hover:scale-90" data-aos="zoom-out">
                <div>
                  <img
                    className="h-full w-[95px] object-cover p-2 rounded-full m-auto"
                    src="./assests/cvlogo.png"
                    alt="Img Here"
                  />
                </div>
                <div className="p-3">
                  <h1 className="text-[color:var(--primary-color)] text-[21px] mb-2 mt-[-22px] ml-4 font-semibold">
                    COMPUTER VISION
                  </h1>
                  <p className="text-[13.5px] text-justify mb-3 text-[color:var(--light-color)] ml-1">
                    Transforming Vision into Insight: Precision, Power,
                    Possibilities with Our Cutting-Edge Computer Vision
                    Services.
                  </p>
                </div>
              </div>
              </a>
              {/* Card 05 */}
              <a href="/" className="transform transition duration-500 hover:scale-90">
              <div className="bg-[color:var(--dark-color)] rounded-[63px]  border border-[color:var(--dark-color)] w-[250px] h-[250px] m-auto transform transition duration-500 hover:scale-90" data-aos="zoom-out">
                <div>
                  <img
                    className="h-full w-[90px] object-cover p-2 m-auto mt-1"
                    src="./assests/graphiclogo.png"
                    alt="Img Here"
                  />
                </div>
                <div className="p-3">
                  <h1 className="text-[color:var(--primary-color)] text-[21px] mb-2 mt-[-18px] font-semibold">
                    GRAPHIC DESIGNING
                  </h1>
                  <p className="text-[13.5px] text-justify mb-3 text-[color:var(--light-color)] ml-1">
                    Crafting Visual Excellence Elevate Your Brand with
                    Innovative Designs through Our
                    Graphic Designing Expertise.
                  </p>
                </div>
              </div>
              </a>
              {/* Card 06 */}
              <a href="/" className="transform transition duration-500 hover:scale-90">
              <div className="bg-[color:var(--dark-color)] rounded-[63px]  border border-[color:var(--dark-color)] w-[250px] h-[250px] m-auto transform transition duration-500 hover:scale-90" data-aos="zoom-out">
                <div>
                  <img
                    className="h-full w-[93px] object-cover p-2 m-auto"
                    src="./assests/editinglogo.png"
                    alt="Img Here"
                  />
                </div>
                <div className="p-3">
                  <h1 className="text-[color:var(--primary-color)] text-[21px] mb-2 mt-[-18px] ml-2 font-semibold">
                    VIDEO & ANIMATION
                  </h1>
                  <p className="text-[13.5px] text-justify mb-3 text-[color:var(--light-color)] ml-1">
                    Elevate Your Story: Seamless Editing, Dynamic Effects, and
                    Flawless Transitions with Our Expert Video Editing Services.
                  </p>
                </div>
              </div>
              </a>
              {/* Card 07 */}
              <a href="/" className="transform transition duration-500 hover:scale-90">
              <div className="bg-[color:var(--dark-color)] rounded-[63px]  border border-[color:var(--dark-color)] w-[250px] h-[250px] m-auto transform transition duration-500 hover:scale-90" data-aos="zoom-out">
                <div>
                  <img
                    className="h-full w-[105px] object-cover p-2 rounded-full mt-[-7px] m-auto"
                    src="./assests/marketinglogo.png"
                    alt="Img Here"
                  />
                </div>
                <div className="p-3">
                  <h1 className="text-[color:var(--primary-color)] text-[22px] mb-2 mt-[-22px] ml-4 font-semibold">
                    Digital Marketing
                  </h1>
                  <p className="text-[13.5px] text-justify mb-3 text-[color:var(--light-color)] ml-1">
                    Digital Domination: Boost Your Brand, Maximize
                    Reach, and Drive Results with Our Strategic Digital
                    Marketing Solutions.
                  </p>
                </div>
              </div>
              </a>
              {/* Card 08 */}
              <a href="/" className="transform transition duration-500 hover:scale-90">
              <div className="bg-[color:var(--dark-color)] rounded-[63px]  border border-[color:var(--dark-color)] w-[250px] h-[250px] m-auto transform transition duration-500 hover:scale-90" data-aos="zoom-out">
                <div>
                  <img
                    className="h-full w-[105px] object-cover p-2 m-auto mt-[-5px]"
                    src="./assests/seologo.png"
                    alt="Img Here"
                  />
                </div>
                <div className="p-3">
                  <h1 className="text-[color:var(--primary-color)] text-[21px] mb-2 mt-[-22px] ml-8 font-semibold">
                    SEO SOLUTIONS
                  </h1>
                  <p className="text-[13.5px] text-justify mb-3 text-[color:var(--light-color)] ml-1">
                    Elevate Your Visibility: Precision-Engineered SEO Solutions
                    for Top Rankings, Increased Traffic, and Sustainable Online
                    Success.
                  </p>
                </div>
              </div>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-[color:var(--main-color)]">
        <div className="max-w-[1200px] m-auto py-[30px]">
          <h1 className="text-4xl font-inter text-center font-bold text-[color:var(--primary-color)] mt-2">Our Clients</h1>
          <LogoMarquee/>
        </div>
      </div>
      <div className="bg-[color:var(--primary-color)]">
        <div className="max-w-[1200px] m-auto py-[30px] px-4">
          <h2 className="text-4xl font-inter text-center font-bold text-[color:var(--dark-color)] mt-2">
            A Message From Our Leader
          </h2>
        </div>
        <div>
          <img
            className="w-[280px] rounded-full m-auto"
            src="./assests/ceo.jpg"
            alt=""
          />
          <div className="bg-[#000b24] w-[200px] m-auto rounded-full">
            <h2 className="text-2xl font-inter text-center text-white mt-2 pt-1">
              ZAID ANWAR
              <p className="text-[12px] mt-[-10px]">Founder & CEO </p>
            </h2>
          </div>
          <div className="bg-[color:var(--primary-color)] max-w-[800px] m-auto">
            <h2 className="px-4 m-auto text-justify max-w-[800px] mt-[30px] font-semibold text-[color:var(--main-color)] lg:text-[22px] pb-[50px]">
              "At Innoventis, we believe in the transformative power of
              technology Our journey has been marked by milestones,
              achievements, and most importantly, the collaborative spirit that
              defines our culture We are not just a software house; we are a
              community of passionate individuals working together to shape the
              future of technology."
            </h2>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
